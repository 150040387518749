import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from './Button';
import Link from './Link';

const showPopup = keyframes`
  0% {
    transform: translateY(400px);
    opacity: 0;
  }
  80% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const hidePopup = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(400px);
    opacity: 0;
  }
`;

const Container = styled.div`
  font-size: 14px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border: 1px solid var(--color-charcoal-v10);
  background-color: white;
  z-index: 1000;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  &.show {
    animation-name: ${showPopup};
    animation-duration: 2s;
  }
  &.hide {
    animation-name: ${hidePopup};
    animation-duration: 0.5s;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    right: 24px;
    bottom: 24px;
    max-width: 480px;
    border-radius: 12px;
    box-shadow: ${(props) => props.theme.shadows.hard};
  }
`;
const Header = styled.div`
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    justify-content: flex-end;
  }

  button {
    min-width: 120px;
  }
  button:first-child {
    margin-right: 12px;
  }
`;

const CookieBanner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const [cookieClass, setVisability] = useState('show');

  useEffect(() => {
    if (!localStorage.getItem('cookiesAccepted')) {
      setCookiesAccepted(false);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setVisability('hide');
    window._hsp?.push(['showBanner']);
    setTimeout(() => {
      setCookiesAccepted(true);
      document.querySelector<HTMLButtonElement>('#hs-eu-confirmation-button')?.click();
    }, 500);
  };
  const declineCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setVisability('hide');
    window._hsp?.push(['revokeCookieConsent']);
    setTimeout(() => {
      setCookiesAccepted(true);
    }, 500);
  };

  if (cookiesAccepted) {
    return null;
  }

  return (
    <Container className={`theme-bluescape-pale ${cookieClass}`}>
      <Header>
        Cookie consent
      </Header>
      <p>
        This website stores cookies on your computer. These cookies are used to collect information
        about how you interact with our website and allow us to remember you. We use this
        information in order to improve and customize your browsing experience and for analytics
        and metrics about our visitors both on this website and other media. To find out more
        about the cookies we use, see our
        {' '}
        <Link href="/privacy-policy" key="/privacy_plicy">Privacy Policy</Link>
      </p>
      <p>
        If you decline, your information won&apos;t be tracked when you visit this website.
        A single cookie will be used in your browser to remember your preference not to be tracked.
      </p>
      <ButtonContainer>
        <Button secondary onClick={() => declineCookies()}>
          Decline
        </Button>
        <Button onClick={() => acceptCookies()}>
          Accept
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default CookieBanner;

import styled from 'styled-components';

type InlineVideoProps = {
  videoId: string;
  autoplay?: boolean;
  type: 'YouTube' | 'Wistia';
}

const Player = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  background-color: black;
`;

const VideoInlinePlayer = ({ videoId, autoplay, type }: InlineVideoProps) => {
  if (type === 'YouTube') {
    return (
      <Player
        title="YouTube Video"
        src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay ? '1' : '0'}`}
        frameBorder="0"
        scrolling="no"
        className="youtube_embed"
        name="youtube_embed"
        allowFullScreen
        allow="autoplay"
      />
    );
  }
  return (
    <Player
      title="Wistia Video"
      src={`https://fast.wistia.net/embed/iframe/${videoId}?autoPlay=${autoplay ? 'true' : 'false'}&videoFoam=false&controlsVisibleOnLoad=false
    `}
      frameBorder="0"
      scrolling="no"
      className="wistia_embed"
      name="wistia_embed"
      allowFullScreen
      allow="autoplay"
    />
  );
};

export default VideoInlinePlayer;

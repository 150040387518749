import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import {
  ComponentProps,
  useCallback, useEffect, useState,
} from 'react';
import { ThemeProvider } from 'styled-components';
import localFont from 'next/font/local';
import VideoPopup, { PlayerCtx, PlayerCtxFunc } from '../src/components/VideoPlayerPopup';
import * as gtag from '../src/helpers/Gtag';
import '../styles/globals.css';
import theme from '../styles/theme';
import CookieBanner from '../src/components/CookieBanner';

const sourceSansPro = localFont({
  fallback: [
    'system-ui',
    'arial',
    'sans-serif',
  ],
  src: [
    {
      path: '../public/fonts/source-sans-pro-v19-latin-200.woff',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-200.woff2',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-300.woff',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-300.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-600.woff',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-600.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-700.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-700.woff',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-900.woff2',
      weight: '900',
      style: 'normal',
    },
    {
      path: '../public/fonts/source-sans-pro-v19-latin-900.woff',
      weight: '900',
      style: 'normal',
    },
  ],
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [videoData, setVideoData] = useState<ComponentProps<typeof VideoPopup>>({ videoId: '', embedData: undefined, type: 'Wistia' });

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (localStorage.getItem('cookiesAccepted')) {
        gtag.pageview(url);
        // window.ll_formalyze?.();
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const handleVideoPlayer = useCallback<PlayerCtxFunc>((
    videoId,
    type,
    embedData,
  ) => setVideoData({
    videoId,
    embedData,
    type,
  }), []);

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
                `}
      </Script>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K7MQCJK');`,
        }}
      />
      <Script
        id="leadlander"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var llcookieless = false;
          var formalyze = [];
          formalyze.auto = true;
          formalyze.callback = function(options) {};
          (function() {
            var a = document.createElement('script');
            a.src = 'https://lltrck.com/scripts/lt-v3.js?llid=33641';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(a, s);
          })();`,
        }}
      />
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/2761879.js"
      />
      <Script
        type="text/javascript"
        id="Posthog"
        strategy="afterInteractive"
      >
        {`
          !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
          posthog.init('phc_raPTe2AsFIDMk7JMo6ug4ODHCfqivA4oebfCOqiBUcK',{api_host:'https://app.posthog.com'})
        `}
      </Script>
      <ThemeProvider theme={theme}>
        <PlayerCtx.Provider value={handleVideoPlayer}>
          <div className={sourceSansPro.className}>
            <Component {...pageProps} />
            {/* key makes sure old embed data is discarded */}
            {videoData.videoId && <VideoPopup key={videoData.videoId} {...videoData} />}
            <CookieBanner />
          </div>
        </PlayerCtx.Provider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;

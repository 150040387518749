import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  layout: {
    maxWidth: '1440px',
  },
  breakpoints: {
    small: '600px',
    medium: '900px',
    large: '1200px',
  },
  shadows: {
    hard: '0px 3px 0px rgba(46, 54, 61, 0.07)',
  },
  transitions: {
    short: '150ms',
  },
};

export default defaultTheme;

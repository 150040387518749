import classNames from 'classnames';
import styled from 'styled-components';
import { CFLinkWithOptionalNewTab } from './Link';
import { useVideoPlayer } from './VideoPlayerPopup';

type Props = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  secondary?: boolean;
  href?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  small?: boolean;
  newTab?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const ButtonIcon = styled.div`
  margin-right: 9px;
  display: inline;
  vertical-align: middle;
`;

const ButtonElement = styled.button`
  display: inline-block;
  border: 2px solid;
  border-radius: 8px;
  text-decoration: none;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 18px;
  white-space: nowrap;
  &.small {
    font-size: 16px;
    padding: 10px 12px;
  }
  &.fullWidth {
    width: 100%;
    text-align: center;
  }
  &[disabled] {
    cursor: default;
  }
`;

export const PrimaryButton = styled(ButtonElement)`
  background: var(--theme-primary-button-background);
  border-color: var(--theme-primary-button-border);
  color: var(--theme-primary-button-color);
  &:hover {
    background: var(--theme-primary-button-background-hover);
    border-color: var(--theme-primary-button-border-hover);
    color: var(--theme-primary-button-color-hover);
  }
  &:active {
    background: var(--theme-primary-button-background-active);
    border-color: var(--theme-primary-button-border-active);
    color: var(--theme-primary-button-color-active);
  }
  &[disabled] {
    background: var(--theme-primary-button-background-disabled);
    border-color: var(--theme-primary-button-background-disabled);
    color: var(--theme-primary-button-color-disabled);
  }
`;

export const SecondaryButton = styled(ButtonElement)`
  background: var(--theme-secondary-button-background);
  border-color: var(--theme-secondary-button-border);
  color: var(--theme-secondary-button-color);
  &:hover {
    background: var(--theme-secondary-button-background-hover);
    border-color: var(--theme-secondary-button-border-hover);
    color: var(--theme-secondary-button-color-hover);
  }
  &:active {
    background: var(--theme-secondary-button-background-active);
    border-color: var(--theme-secondary-button-border-active);
    color: var(--theme-secondary-button-color-active);
  }
  &[disabled] {
    background: var(--theme-secondary-button-background-disabled);
    border-color: var(--theme-secondary-button-border-disabled);
    color: var(--theme-secondary-button-color-disabled);
  }
`;

const Button = ({
  children, href, secondary = false, fullWidth = false, small = false, disabled = false, onClick,
  newTab = undefined, type,
}: Props) => {
  const Element = secondary ? SecondaryButton : PrimaryButton;
  return (
    <Element className={classNames('button', { secondary, small, fullWidth })} as={href ? 'a' : 'button'} href={href} rel={newTab ? 'noopener' : undefined} onClick={onClick} disabled={disabled} target={newTab ? '_blank' : undefined} type={type}>
      {children}
    </Element>
  );
};

export const AutoButton = ({
  newTab, title, page, url, wistiaVideoId, children, ...props
}: CFLinkWithOptionalNewTab & Omit<Props, 'href' | 'newTab'> & { children?: React.ReactNode }) => {
  const isBlog = page?.__typename === 'BlogPost';
  const to = (isBlog ? '/blog/' : '') + (page?.slug || url || '#');
  const setVideoData = useVideoPlayer();
  const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    if ('key' in e) {
      if (e.key !== 'Enter') return;
    }
    if (wistiaVideoId) {
      setVideoData(wistiaVideoId, 'Wistia');
    }
  };
  if (wistiaVideoId) {
    return <Button onClick={handleClick}>{children || title}</Button>;
  }

  return (
    <Button newTab={newTab} href={to} {...props}>{children || title}</Button>
  );
};

export default Button;

import React, {
  createContext, useCallback, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import { WistiaOEmbedData } from '../../lib/types';
import VideoInlinePlayer from './InlineVideoPlayer';

export interface VideoPopupProps {
  videoId: string;
  embedData?: WistiaOEmbedData;
  type: 'YouTube' | 'Wistia';
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3032387f;

  &, * {
    box-sizing: border-box;
  }
`;

const PlayerWrapper = styled.article`
  background-color: white;
  padding: 32px;
  border-radius: 16px;
  width: min(50vw, 1440px);
  max-width: 110vh;

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    width: 80vw;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    width: 90vw;
    padding: 24px;
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

export type PlayerCtxFunc = (videoId: string, type: 'YouTube' | 'Wistia', embedData?: WistiaOEmbedData) => void;
export const PlayerCtx = createContext<PlayerCtxFunc>(() => {});

export const useVideoPlayer = () => {
  const play = useContext(PlayerCtx);
  return play;
};

const Title = styled.h2`
  font-size: 18px;
  margin: 0;
  word-wrap: break-word;
  font-weight: 600;
`;

const CloseButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-image: url('/static/images/close.svg');
`;

const Close = (props: { onClick: () => void}) => (
  <CloseButton onClick={props.onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="#2E363D"
        fillRule="evenodd"
        d="M.3.3a1 1 0 0 1 1.4 0L7 5.6 12.3.3a1 1 0 1 1 1.4 1.4L8.4 7l5.3 5.3a1 1 0 0 1-1.4 1.4L7 8.4l-5.3 5.3a1 1 0 0 1-1.4-1.4L5.6 7 .3 1.7A1 1 0 0 1 .3.3Z"
        clipRule="evenodd"
      />
    </svg>
  </CloseButton>
);

const VideoPopup: React.FC<VideoPopupProps> = ({ videoId, embedData, type }) => {
  const setVideoId = useContext(PlayerCtx);
  const [
    fetchedEmbedData,
    setFetchEmbedData,
  ] = React.useState<WistiaOEmbedData | undefined>(embedData);
  useEffect(() => {
    if (embedData) { return; }
    void fetch(`https://fast.wistia.com/oembed?url=https://bluescape.wistia.com/medias/${videoId}`)
      .then((r) => {
        if (r.ok) { return r.json() as Promise<WistiaOEmbedData>; }
        throw new Error('Failed to fetch embed data');
      })
      .then((d) => setFetchEmbedData(d))
      // eslint-disable-next-line no-console
      .catch((e) => console.warn(e));
  }, [videoId, embedData]);

  const handleClose = useCallback(() => {
    setVideoId('', 'Wistia');
  }, [setVideoId]);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    });
  }, [handleClose]);

  return (
    <Overlay onClick={(e) => {
      if (e.target === e.currentTarget) {
        e.stopPropagation();
        handleClose();
      }
    }}
    >
      <PlayerWrapper>
        <header>
          <Title>{fetchedEmbedData?.title}</Title>
          <Close onClick={handleClose} />
        </header>
        <VideoInlinePlayer type={type} videoId={videoId} autoplay />
      </PlayerWrapper>
    </Overlay>
  );
};

export default VideoPopup;

// import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { CFLink } from '../../lib/types';
import { useVideoPlayer } from './VideoPlayerPopup';
// import { PrimaryButton } from './Button';

// To allow as= to work with wistiaVideoId (for example ArrowLink)
const DefaultLink = styled.a<{isDiv?: boolean}>``;

type Props = React.ComponentProps<typeof DefaultLink> & {
  href: string
  children: React.ReactNode;
  style?: React.CSSProperties;
  newTab?: boolean;
  as?: typeof DefaultLink;
};

const StyledImage = styled(Image)`
  object-fit: contain;
`;

const Link = ({
  children, newTab, href, as: component, ...props
}: Props) => {
  const Component = component || DefaultLink;
  return (
  // <Link href={href} passHref>
    <Component
      href={href}
      rel={newTab ? 'noopener' : undefined}
      target={newTab ? '_blank' : undefined}
      {...props}
    >
      {children}
    </Component>
  // </Link>
  );
};

export const linkToHref = ({ page, url }: Pick<CFLink, 'page' | 'url'>) => {
  const isBlog = page?.__typename === 'BlogPost';
  const to = (isBlog ? '/blog/' : '') + (page?.slug || url || '#');
  return to;
};

export type CFLinkWithOptionalNewTab = Omit<CFLink, 'newTab'> & { newTab?: boolean };

export const AutoLink = ({
  newTab, title, page, url, wistiaVideoId, children, image, ...props
}: CFLinkWithOptionalNewTab & Omit<Props, 'href' | 'newTab'> & { children?: React.ReactNode }) => {
  const to = linkToHref({ page, url });
  const setVideoData = useVideoPlayer();
  const { as: asProp } = props;
  const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    if ('key' in e) {
      if (e.key !== 'Enter') return;
    }
    if (wistiaVideoId) {
      setVideoData(wistiaVideoId, 'Wistia');
    }
  };
  if (wistiaVideoId) {
    const Element = asProp as typeof DefaultLink || DefaultLink;
    return <Element className="link" role="button" isDiv tabIndex={0} onKeyDown={handleClick} onClick={handleClick} as="div">{children || title}</Element>;
  }

  if (image && !children) {
    return (
      <Link newTab={newTab} href={to} title={title} {...props}>
        <StyledImage
          src={image.url}
          alt={image.description || image.title}
          width={image.width}
          height={image.height}
        />
      </Link>
    );
  }

  return (
    <Link newTab={newTab} href={to} {...props}>{children || title}</Link>
  );
};

export default Link;
